export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const UPDATE_PRODUCT_QUANTITY = 'UPDATE_PRODUCT_QUANTITY'
export const CHECKOUT = 'CHECKOUT'
export const APPLY_DISCOUNT = 'APPLY_DISCOUNT'
export const LS_CART_NAME = 'HONESTPAWS-CART'
export const COOKIE_CART_NAME = 'HONESTPAWS-LAST-CART'
export const ITEMS_PROP_NAME = 'itemsLines'
export const UTM_PROP_NAME = 'utmParams'
export const UTM_FA_PROP_NAME = 'utmFAParams'
export const COUPON_PROP_NAME = 'discount'
export const RESTORE_CART = 'restoreCart'
export const EMAIL_PROP_NAME = 'email'