module.exports.SALE_TYPE_SUBSCRIPTION = 'subscription'
module.exports.SALE_TYPE_STRAIGHT = 'straight'
module.exports.MODEL_PRODUCT = 'product'
module.exports.MODEL_BLOGPOST = 'blog_post'
module.exports.MODEL_YOTPO_REVIEWS_META = 'yotpo_reviews_meta'

module.exports.ALL_PRODUCT_CATEGORIES_LABEL = 'all types'
module.exports.ALL_BLOG_CATEGORIES_LABEL = 'all'

module.exports.ALL_PRODUCT_COLLECTIONS_LABEL = 'all'

module.exports.YOTPO_WIDGETS = {
  SITE: 'site-reviews',
  PRODUCT: 'product-review'
}

module.exports.ALGOLIA_INDICES = ['Products', 'Collections', 'Posts', 'Pages']
module.exports.ALGOLIA_GA_EVENTNAME = 'algolia_search'
module.exports.ALGOLIA_GA_PROPNAME = 'algolia_query'
module.exports.ALGOLIA_DELAY = 1000

module.exports.WIDTH_BREAKPOINTS = {
  lg: 992
}

module.exports.SHOPIFY_CUSTOMER_COOKIE = 'shopify_customer'