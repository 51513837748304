import { findIndex } from 'lodash'
import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_PRODUCT_QUANTITY, LS_CART_NAME, COOKIE_CART_NAME, CHECKOUT, 
  APPLY_DISCOUNT, ITEMS_PROP_NAME, RESTORE_CART } from './constants'
import { SALE_TYPE_SUBSCRIPTION } from '../../constants'
import { extractImageFromProduct, triggerEvent, castToInternalShopifyId } from '../../helpers'
import  Cookies from 'js-cookie'

/**
 * Syncs cart with localStorage
 * @param  {[type]}  state
 * @return {Boolean}
 */
const syncCartStateWithLocalStorage = (state, action = 'set', name = '', cart = null) => {
  cart = cart || state
  name = name || LS_CART_NAME
  if (typeof window !== 'undefined' && 'localStorage' in window) {
    switch (action) {
      case 'set': 
        return localStorage.setItem(name, JSON.stringify(cart))
      case 'get':
        return JSON.parse(localStorage.getItem(name))
      case 'remove':
        return localStorage.removeItem(name)
      case 'replicate':
        localStorage.setItem(name, JSON.stringify(cart))
        
        return JSON.parse(localStorage.getItem(name))
    }    
  }
  
  return null
}

/**
 * Handles "last cart" cookie. It will take care of a previous cart if there is one stored
 * @param  {String} [action='get']
 * @param  {String} cartName
 * @return {String}
 */
const handleCartCookie = (action = 'get', cartName = '') => {
  switch (action) {
    case 'set': 
      return Cookies.set(COOKIE_CART_NAME, cartName, {
        expires: 365,
        domain: window.location.host.replace('www', '').replace(/:.+/, '')
      })
    case 'get':
      return Cookies.get(COOKIE_CART_NAME)
    case 'remove':
      return Cookies.remove(COOKIE_CART_NAME)
  }  
}

/**
 * Retrieves initial state for cart
 * @param  {Boolean} [forceEmpty=false] 
 * @return {[type]}
 */
const getInitialState = (forceEmpty = false) => {  
  let emptyCart = {
    [ITEMS_PROP_NAME]: []
  }
  
  if (forceEmpty) {
    return emptyCart
  }
  
  let lsCart = syncCartStateWithLocalStorage([], 'get')
  
  if (!lsCart) {
    /** If there is no cart in the local storage, then we will want to check if there a cookie storing the information about the last known cart from the user
    before they checked out **/
    const ckCart = handleCartCookie()
    if (ckCart) {
      /** If we find such a cookie, then we try to recover the cart from the localstorage **/
      /** We remove the cookie */
      handleCartCookie('remove')
      lsCart = syncCartStateWithLocalStorage({}, 'get', ckCart)
      /** If we find the cart, then we simply remove it from the local storage because we don't want to keep it any more **/
      syncCartStateWithLocalStorage({}, 'remove', ckCart)
      /** We set the cart as the current one **/
      syncCartStateWithLocalStorage(lsCart, 'set')
    }
  }
  
  return lsCart || emptyCart
}


const initialState = getInitialState()

/**
 * Build a product for the cart (returns it in the way we expect it)
 * @param  {Object} product
 * @param  {Object} productVariant
 * @return {Object}
 */
const buildProductForCart = (item) => {
  const product = item.product
  const variant = item.variant
  const variantIndex = 'variantIndex' in item ? item.variantIndex : -1
  let productLine = {
    id: variant.id,
    product_id: product.id,
    image: extractImageFromProduct(product, variant),
    product_type: product.productType,
    handle: product.handle,
    price: variant.price,
    sku: variant.sku,
    title: `${product.title} ${variant.title !== 'Default Title' ? variant.title : ''}`,
    product_group_sku: product.variants[0].sku,
    [SALE_TYPE_SUBSCRIPTION]: false
  }
  
  if (item[SALE_TYPE_SUBSCRIPTION]) {
    productLine[SALE_TYPE_SUBSCRIPTION] = true
  }
  
  // Recharge needs variant id to be original product's variant id, not subscription variant id
  if (variantIndex >= 0) {
    productLine.id = product.variants[variantIndex].id
  }
  return productLine
}

/**
 * Finds product's index in the cart
 * @param  {Object} product
 * @return {Number}
 */
const findLineIndexInCart = (state, product) => {
  let itemsLines = state[ITEMS_PROP_NAME]
  
  return findIndex(itemsLines, item => {
    return item.product.id === product.id && item.product[SALE_TYPE_SUBSCRIPTION] === product[SALE_TYPE_SUBSCRIPTION]
  })
}

/**
 * Removes an item from the cart
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
const removeFromCart = (state, action) => {
  // We want to create a new array so we do it in this way
  let itemsLines = [
    ...state[ITEMS_PROP_NAME]
  ]
  // const { productId, [SALE_TYPE_SUBSCRIPTION] } = action.item
  const productId = action.item.productId
  const isSubscription = action.item[SALE_TYPE_SUBSCRIPTION]
  const lineIndex = findLineIndexInCart(state, { id: productId, [SALE_TYPE_SUBSCRIPTION]: isSubscription})
  if (lineIndex >= 0) {
    //*** Comment this lines because we don't want to reduce the qty when we use the removeFromCart Function

    // const item = itemsLines[lineIndex]
    // if (item.qty > 1) {
    //   item.qty -= 1
    // } else {
    // }
    itemsLines.splice(lineIndex, 1)
    return {
      ...state,
      [ITEMS_PROP_NAME]: itemsLines
    }      
  }
  
  return state
}

/**
 * Adds an item to the cart
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
const addToCart = (state, action) => {
  const product = buildProductForCart(action.item)
  let itemsLines = state[ITEMS_PROP_NAME]
  const lineIndex = findLineIndexInCart(state, product)
  const addedQty = parseInt(action.item.qty) || 1
  if (lineIndex >= 0) {
    return {
      ...state,
      [ITEMS_PROP_NAME]: itemsLines.map((item, index) => {
        let qty = item.qty
        if (index === lineIndex) {
          qty += addedQty
        }
        return {
          ...item,
          qty
        }
      })
    }
  } else {
    trackQtyEvent(1, product)
    return {
      ...state,
      [ITEMS_PROP_NAME]: [
        ...state.itemsLines,
        {
          product, 
          qty: addedQty,
          [SALE_TYPE_SUBSCRIPTION]: action.item[SALE_TYPE_SUBSCRIPTION]
        }
      ]
    }      
  }
}

/**
 * Increases, decreases or removes product based on quantity
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
const updateProductQuantity = (state, action) => {
  let itemsLines = state[ITEMS_PROP_NAME]
  const { productId, qty } = action.item
  const lineIndex = findLineIndexInCart(state, { id: productId, [SALE_TYPE_SUBSCRIPTION]: action.item[SALE_TYPE_SUBSCRIPTION] })
  if (lineIndex >= 0) {
    if (qty > 0) {
      return {
        ...state,
        [ITEMS_PROP_NAME]: itemsLines.map((item, index) => {
          let itemQty = item.qty
          if (index === lineIndex) {
            trackQtyEvent(qty - itemQty, itemsLines[lineIndex].product)
            itemQty = qty
          }
          return {
            ...item,
            qty: itemQty
          }
        })
      } 
    } else {
      trackQtyEvent(-1, itemsLines[lineIndex].product)
      return removeFromCart(state, action)
    }
  }
  
  return state
}

/**
 * Tracks `add_to_cart` and `remove_from_cart` events
 * @param  {Number} qtyDiff 0+ means product was added | -0 means product was removed
 * @param  {Number} product
 * @return {void}
 */
const trackQtyEvent = (qtyDiff, product) => {
  let key = ''
  let event = ''
  if (qtyDiff >= 0) {
    event = 'add_to_cart'
    key = 'add'
  } else {
    event = 'remove_from_cart'
    key = 'remove'
  }
  
  triggerEvent(event, {
    ecommerce: {
      currencyCode: 'USD',
      [key]: {
        products: [{
          id: product.sku,
          name: product.title,
          price: product.price,
          product_type: product.product_type,
          handle: product.handle,
          product_group_sku: product.product_group_sku,
          image: product.image.fixed.src,
          quantity: 1
        }]
      }
    }
  })
}

export const actions = (state = initialState, action) => {
  let trackEvent = ''
  switch (action.type) {
    case ADD_TO_CART: {
      state = addToCart(state, action)
      syncCartStateWithLocalStorage(state, 'set')
    }
    break
    case REMOVE_FROM_CART: {
      state = removeFromCart(state, action)
      syncCartStateWithLocalStorage(state, 'set')
    } 
    break
    case UPDATE_PRODUCT_QUANTITY: {
      state = updateProductQuantity(state, action)
      syncCartStateWithLocalStorage(state, 'set')
    } 
    break
    case RESTORE_CART: {
      syncCartStateWithLocalStorage(state, 'set', action.item.name, action.item.cart)
      state = syncCartStateWithLocalStorage(state, 'get', action.item.name)
    } 
    break
    case CHECKOUT: {
      const token = (action.item && action.item.token) ? action.item.token : Math.random()
      const currentCart = syncCartStateWithLocalStorage(state, 'get', token)
      const currentCartName = `${LS_CART_NAME}_${token}`
      syncCartStateWithLocalStorage(state, 'replicate', currentCartName, currentCart)
      handleCartCookie('set', currentCartName)
      syncCartStateWithLocalStorage(state, 'remove')
      state = getInitialState(true)
    } 
    break
  }
  
  if (action.type === ADD_TO_CART || action.type === REMOVE_FROM_CART || action.type === UPDATE_PRODUCT_QUANTITY) {  
    triggerEvent('cart_updated', {
      cart: {
        items: state[ITEMS_PROP_NAME].map(item => ({
          id: item.product.sku,
          name: item.product.title,
          price: item.product.price,
          product_type: item.product.product_type,
          handle: item.product.handle,
          product_group_sku: item.product.product_group_sku,
          image: item.product.image.fixed.src,
          quantity: item.qty
        })),
        last_action: action.type
      }
    })
  }
  
  return state
}

export const getItemsLines = state => state.itemsLines
export { syncCartStateWithLocalStorage }