const axios = require ("axios");
const sanitizeHtml = require("sanitize-html")
const { kebabCase, get } = require("lodash")
const { decode } = require('base-64')
const {
    MODEL_PRODUCT,
    MODEL_YOTPO_REVIEWS_META,
    ALL_PRODUCT_CATEGORIES_LABEL,
    ALL_BLOG_CATEGORIES_LABEL,
    ALL_PRODUCT_COLLECTIONS_LABEL,
    SHOPIFY_CUSTOMER_COOKIE
} = require('./constants')
const Cookies = require('js-cookie')
const smoothscroll = require('smoothscroll-polyfill');

/**
 * Checks if an url is external or is a has or a mailto
 * @param  {String}  url
 * @return {Boolean}
 */
module.exports.isExternalUrlOrHash = (url) => url && url.match(/^((https?)?:\/\/|mailto:|tel:|#)/) || url === '#'

/**
 * Checks if an url is is or contains a hash
 * @param  {String}  url
 * @return {Boolean}
 */
module.exports.isHash = (url) => url && url.match(/^#/) || url === '#'

/**
 * Extracts Contentful Objects properties based on language
 * @param  {Object} node
 * @param  {String} [localization='en-US']
 * @return {Object}
 */
module.exports.contentfulMapToLocalized = (node, localization = 'en-US') => {
    let newNode = {}
    for (let field in node) {
        if (node[field]) {
            newNode[field] = node[field]['en-US']
        }
    }

    return newNode
}

/**
 * Parses text to html
 * @param  {String} htmlText
 * @param  {Array} allowedTags
 * @param  {Array} allowedAttributes
 * @return {Object}
 */
module.exports.htmlFromText = (htmlText, allowedTags, allowedAttributes) => {
    // Default options can be seen at: https://www.npmjs.com/package/sanitize-html#what-are-the-default-options
    let options = {}
    if (allowedTags) {
        options.allowedTags = allowedTags
    }
    if (allowedAttributes) {
        options.allowedAttributes = allowedAttributes
    }
    return {
        __html: sanitizeHtml(htmlText, options)
    }
}

/**
 * Adds a trailing slash to links
 * @param  {String} link
 * @return {String}
 */
module.exports.slashLink = link => link.match(/(#.*\/?$|\/$)/) ? link : `${ link }/`

/**
 * Creates blog's link
 * @param  {String} [category=null]
 * @param  {Number} [page=1]
 * @param  {String} [prefix='']
 * @return {String}
 */
const blogUrl = (category = null, page = 1, prefix = '') => {
    let path = `${prefix}/blog/`
    if (category && category !== ALL_BLOG_CATEGORIES_LABEL) {
        path += `${kebabCase(category)}/`
    }
    if (page && page > 1) {
        path += `${page}/`
    }

    return path
}
module.exports.blogUrl = blogUrl

/**
 * Creates blog Reviews List link
 * @param  {String} [prefix='']
 * @return {String}
 */
const blogReviewsListUrl = (category = null, page = 1, prefix = '') => {
  let path = `${prefix}/reviews/`
  if (category) {
    path += `${kebabCase(category)}/`
  }
  if (page && page > 1) {
    path += `${page}/`
  }

  return path
}
module.exports.blogReviewsListUrl = blogReviewsListUrl


/**
 * Creates url structure for posts
 * @param  {Object} post
 * @param  {String} prefix
 * @return {String}
 */
const postUrl = (post, prefix = '') => `${prefix}${blogUrl()}${post.slug}/`
module.exports.postUrl = postUrl

/**
 * Creates url structure for cluster posts
 * @param  {Object} post
 * @param  {String} prefix
 * @return {String}
 */
module.exports.postClusterUrl = (post, prefix = '') => post.cluster ? `${prefix}/${kebabCase(post.cluster)}/${post.slug}/` : postUrl(post, prefix)

/**
 * Creates url structure for Blog Reviews
 * @param  {Object} review
 * @param  {String} prefix
 * @return {String}
 */
module.exports.blogReviewUrl = (review, prefix = '') => `${prefix}/reviews/${kebabCase(review.category)}/${review.slug}/`

/**
 * Creates url structure for authors
 * @param  {Object} author
 * @param  {String} [prefix='']
 * @return {String}
 */
module.exports.authorUrl = (author, prefix = '') => `${prefix}/authors/${kebabCase(author.name)}/`

/**
 * Creates url structure for pages
 * @param  {String} page
 * @param  {String} [prefix='']
 * @return {String}
 */
module.exports.pageUrl = (page, prefix = '') => `${prefix}/${page}/`

/**
 * Creates categories' link
 * @param  {String} [category=null]
 * @param  {Number} [page=1]
 * @param  {String} [prefix='']
 * @return {String}
 */
const productCategoryUrl = (category = null, page = 1, prefix = '') => {
    let path = `${prefix}/collections/`
    if (category && category !== ALL_PRODUCT_CATEGORIES_LABEL) {
        path += `${kebabCase(category)}/`
    }
    if (page && page > 1) {
        path += `${page}/`
    }

    return path
}
module.exports.productCategoryUrl = productCategoryUrl

/**
 * Creates collections' link
 * @param  {String} [collection=null] -> Same as category now
 * @param  {Number} [page=1]
 * @param  {String} [prefix='']
 * @return {String}
 */
const collectionUrl = (collection = null, page = 1, prefix = '') => {
    let path = `${prefix}/collections/`
    if (collection && collection !== ALL_PRODUCT_COLLECTIONS_LABEL) {
        path += `${kebabCase(collection)}/`
    }
    if (page && page > 1) {
        path += `${page}/`
    }

    return path
}
module.exports.collectionUrl = collectionUrl

/**
 * Creates How To Guides' Link
 * @param {String} howtoguide
 * @param {String} [prefix='']
 * @returns {string}
 */
const howToGuideUrl = (howtoguide = null, prefix = '') => {
    let path = `${prefix}/how-to-guide/`
    if (howtoguide) {
        path += `${kebabCase(howtoguide)}/`
    }

    return path
}
module.exports.howToGuideUrl = howToGuideUrl

/**
 * Creates url structure for posts
 * @param  {Object} post
 * @param  {String} prefix
 * @return {String}
 */
const productUrl = (product, prefix = '') => {
  let path = ''
  if (product.variant) {
    path = `${prefix}/products/${product.handle}/?variant=${product.variant}`
  } else {
    path = `${prefix}/products/${product.handle}/`
  }

  return path
}
module.exports.productUrl = productUrl

/**
 * Creates array containing widths for setting Img sizes and srcset
 * @param  {Object} image
 * @param  {Number} [minWidth=300]
 * @param  {Number} [widthHop=200]
 * @param  {Number} [maxWidth=null]
 * @return {Array}
 */
module.exports.createWidthHopsForImage = (image, minWidth = 300, widthHop = 200, maxWidth = null) => {
    maxWidth = maxWidth ? maxWidth : image.width
    let currentWidth = minWidth
    let widths = [];
    do {
        widths.push(currentWidth)
        currentWidth += widthHop
    } while (currentWidth < maxWidth)

    widths.push(maxWidth)

    return widths
}

/**
 * Defines categories labels
 * @type {Object}
 */
const blogPostCategoryLabels = {
    all: 'All',
    cbd: 'About CBD',
    health: 'Health',
    'customer success stories': 'Customer Success Stories',
    'pet care': 'Pet Care',
    'news': 'News',
    medication: 'Medication',
    'special reports': 'Special Reports'
}
module.exports.blogPostCategoryLabels = blogPostCategoryLabels

/**
 * Defines categories descriptions
 * @type {Object}
 */
const blogPostCategoryDescriptions = {
  all: '',
  cbd: '',
  health: 'Serious health issues in your animal friend can be challenging for any pet parent to deal with. However, knowledge is power, and our library of information can help you make more informed decisions about your pet\'s care.',
  'customer success stories': 'The Honest Paws Pack is filled with dogs and cats who are living their best lives thanks to CBD. See how Honest Paws has helped pets all across the nation with issues like mobility, anxiety, and more!',
  'pet care': 'Supporting your companion animal\'s health and happiness all starts with pet care basics that focus on diet, exercise, holistic practices, and more!',
  'news': 'Discover what\'s trending in the world of pet news and stay up to date with special announcements and celebrations from the Honest Paws team.',
  medication: 'No one wants to see their companion animal in pain or discomfort. Conventional medicine is often a pet parent\'s first approach to help their dog or cat, but it\'s important to know which medications are safe for your pet, and when holistic alternatives might be the better option.',
  'special reports': 'These informative and engaging content pieces span a variety of topics, from investigative reports and whistle-blowing exposés to entertaining listicles and educational infographics.'
}
module.exports.blogPostCategoryDescriptions = blogPostCategoryDescriptions

/**
 * Defines categories images
 * @type {Object}
 */
const blogPostCategoryImages = {
  all: '',
  cbd: 'D-Category-About-CBD.png',
  health: 'D-Category-Health.png',
  'customer success stories': '',
  'pet care': 'D-Category-Pet-Care.png',
  'news': 'D-Category-News.png',
  medication: 'D-Category-Medications.png',
  'special reports': 'D-Special-Report.png'
}
module.exports.blogPostCategoryImages = blogPostCategoryImages

/**
 * Defines categories images
 * @type {Object}
 */
const blogPostCategoryBackgroundImages = {
  all: '',
  cbd: 'Banner-About-CBD-v2.png',
  health: 'Banner-Health-v2.png',
  'customer success stories': 'Banner-Customer-Stories-v2.png',
  'pet care': 'Banner-Pet-Care-v2.png',
  'news': 'Banner-News-v2.png',
  medication: 'Banner-Medications-v2.png',
  'special reports': 'Banner-Special-Reports-v2.png'
}
module.exports.blogPostCategoryBackgroundImages = blogPostCategoryBackgroundImages

/**
 * Maps shopify's product gallery to the type of images we use
 * @param  { Array | Object } images
 * @return { Array }
 */
module.exports.mapShopifyProductImages = (images) => {
  images = images instanceof Array === true ? images : [ images ]
  return images.map( image => ({
    description: image.altText || 'Product Image',
    id: image.id,
    fluid: image.localFile.childImageSharp.fluid,
    fixed: image.localFile.childImageSharp.fixed,
    mobileFluid: image.localFile.childImageSharp.mobileFluid,
    thumbnailFluid: image.localFile.childImageSharp.thumbnailFluid,
    fullFluid: image.localFile.childImageSharp.fullFluid,
    type: image.type || 'image'
  }))
}

/**
 * makes foreach support async
 * @param  {Array}   array
 * @param  {Function} callback
 * @return {Promise}
 */
const asyncForEach = (array, callback) => {
    return new Promise(async(resolve, reject) => {
        let promises = []
        length = array.length
        for (let index = 0; index < length; index++) {
            promises.push(new Promise(async(resolve, reject) => {
                await callback(array[index], index, array);
                resolve()
            }))
        }
        await Promise.all(promises)
        resolve()
    })
}
module.exports.asyncForEach = asyncForEach

/**
 * Parses a Contentful's Shopify Product to a known format for us
 * @param  {Object} product
 * @return {Object}
 */
module.exports.parseContentfulShopifyProduct = (product) => {
    shopifyProduct = product.shopifyProduct || product
    ownRelatedProductDescription = product.ownRelatedProductDescription || ''
    return {
        ...shopifyProduct,
        id: shopifyProduct.shopifyId,
        ownRelatedProductDescription: ownRelatedProductDescription,
        images: shopifyProduct.images.edges.map(({ node }) => ({
            ...node
        })),
        variants: shopifyProduct.variants.edges.map(({ node }) => ({
            ...node
        }))
    }
}

/**
 * Extracts an image from a product 
 * @param  {Object} product
 * @param  {Object} [variant=null]
 * @return {Object | String}
 */
module.exports.extractImageFromProduct = (product, variant = null) => {
    let image
    if (variant && variant.image) {
        if (variant.image.localFile) {
            image = variant.image.localFile.childImageSharp
        } else if (variant.image.originalSrc) {
            image = variant.image.originalSrc
        }
    } else if (product && product.images) {
        if (product.images[0].localFile) {
            image = product.images[0].localFile.childImageSharp
        } else if (product.images[0].originalSrc) {
            image = product.images[0].originalSrc
        }
    }

    return image
}

/**
 * Decodes an id obtained via GraphQL and retrieves the corresponding internal one
 * @param  {String} id
 * @return {String}
 */
const castToInternalShopifyId = (id) => {
    try {
        const decoded = decode(id.replace(/Shopify__[a-zA-Z]+__/, '')) // Shopify__Product__ is added by graphql plugin
        return decoded.replace(/[^0-9]+/, '') // Because this will be shape of the previus output: gid://shopify/Product/4185077219414
    } catch (e) {
        // Means it's already decoded
        // console.log(e)
        return id
    }

    return null
}
module.exports.castToInternalShopifyId = castToInternalShopifyId

/**
 * Builds JSON schema (structured data) for products
 * @param  {Product} product
 * @return {Object}
 */
const buildStructuredDataJsonForProduct = (product, extended = true) => {
    const internalShopifyId = castToInternalShopifyId(product.id)
    let structuredJson = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        '@id': internalShopifyId,
    }

    if (!extended) {
        return structuredJson
    }

    structuredJson = {
        ...structuredJson,
        url: productUrl(product),
        name: product.title,
        image: product.images[0].originalSrc,
        description: product.description,
        productId: internalShopifyId,
        itemCondition: 'https://schema.org/NewCondition',
        // ToDo - Check how we can improve this to make it dynamic
        brand: {
            '@type': 'Brand',
            'name': 'Honest Paws'
        },
        additionalProperty: [{
            '@type': 'PropertyValue',
            additionalProperty: 'item_group_id',
            value: product.variants[0].sku
        }]
    }

    let variants = product.variants
    if (product.subscriptionVariants) {
        variants = variants.concat(product.subscriptionVariants)
    }
    // Now variants has the data of the product and the subscription product too

    let highPrice = Math.max.apply(Math, variants.map(function(variant) {
            return variant.price
        })) // the highest price of all the variants

    let lowPrice = Math.min.apply(Math, variants.map(function(variant) {
            return variant.price
        })) // the lowest price of all the variants

    structuredJson.mpn = variants[0].sku
    structuredJson.sku = variants[0].sku
    structuredJson.offers = {
        '@type': "AggregateOffer",
        priceCurrency: 'USD',
        lowPrice: lowPrice,
        highPrice: highPrice,
        offers: variants.map(variant => ({
            sku: variant.sku,
            mpn: variant.sku,
            '@type': "Offer",
            price: variant.price,
            image: variant.image.originalSrc,
            url: `${productUrl(product)}?variant=${castToInternalShopifyId(variant.shopifyId)}`,
            priceCurrency: 'USD',
            availability: variant.availableForSale ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
            itemCondition: 'https://schema.org/NewCondition',
            priceValidUntil: new Date(new Date().getFullYear(), 11, 31).toISOString(), // Last day of year
        }))
    }

    return structuredJson
}

/**
 * Builds JSON schema (structured data) for yotpo reviews
 * @param  {Object} yotpoReviewsMetaModel
 * @param  {Boolean} extended if true, then this will be a review by itself (TODO), otherwise it will retrieve a schema to append to something else
 * @return {Object}
 */
const buildStructuredDataJsonForYotpoReviewsMeta = (yotpoReviewsMetaModel, extended = false) => {
    let structuredJson = {}

    if (yotpoReviewsMetaModel) {
        if (yotpoReviewsMetaModel.bottomline && yotpoReviewsMetaModel.bottomline.total_review) {
            structuredJson.aggregateRating = {
                '@type': 'AggregateRating',
                ratingCount: yotpoReviewsMetaModel.bottomline.total_review,
                ratingValue: yotpoReviewsMetaModel.bottomline.average_score
            }
        }
        if (yotpoReviewsMetaModel.reviews) {
            structuredJson.review = yotpoReviewsMetaModel.reviews.map(review => ({
                '@type': 'Review',
                reviewRating: {
                    '@type': 'Rating',
                    ratingValue: review.score
                },
                author: {
                    '@type': 'Person',
                    name: review.user.display_name
                },
                reviewBody: review.content
            }))
        }
    }

    return structuredJson
}

/**
 * Builds JSON schema (structured data) for lists
 * Examples: https://www.endurantdevs.com/blog/values-provided-url-must-point-page-problem-googles-sdtt/
 * @param  {Object} collection
 * @param  {Array} items
 * @param  {String} itemsType
 * @param  {String|null} id id to be used for recognizing this collection
 * @param  {Object} extra If needs to be extended with this
 * @return {Object}
 */
module.exports.extractStructuredDataJsonFromCollection = (collection, items, itemsType, id = null, extra = {}) => {
  
    if (items instanceof Array === false) {
        return {}
    }
    let structuredJson = {
        '@context': 'https://schema.org/',
        '@type': 'CollectionPage',
        'name': collection.title,
        'description': collection.description,
    }
    
    if (id) {
      structuredJson['@id'] = id
    }
    
    structuredJson.mentions = items.map((m, i) => {
        let item = {}
        if (itemsType === MODEL_PRODUCT) {
          item = {
            ...extractStructuredDataJson(m, itemsType, true),
            '@type': 'Product'
          }
          const reviews = get(m, 'reviews')
          if (reviews) {
            item = {
              ...item,
              ...buildStructuredDataJsonForYotpoReviewsMeta(reviews)
            }
          }
        }
        
        return item
    })

    return {
      ...structuredJson,
      ...extra
    };
}

/**
 * Builds JSON schema (structured data)
 * @param  {Object} model
 * @param  {String} type
 * @param  {Boolean} extended
 * @return {Object}
 */
const extractStructuredDataJson = (model, type, extended = true) => {
    switch (type) {
        case MODEL_PRODUCT:
            return buildStructuredDataJsonForProduct(model, extended)
        case MODEL_YOTPO_REVIEWS_META:
            return buildStructuredDataJsonForYotpoReviewsMeta(model, extended)
    }
}
module.exports.extractStructuredDataJson = extractStructuredDataJson

/**
 * Builds breadcrumb JSON schema from location
 * @param  {Array} breadcrumbs Expects array of objects structured in the following share: {name: '', path :''}
 * @return {Object}
 */
module.exports.extractBreadcrumbsStructuredDataJsonFromLocation = (breadcrumbs) => {
    if (!breadcrumbs) {
        return {}
    }
    let structuredJson = {
        '@context': 'https://schema.org/',
        '@type': 'BreadcrumbList',
        '@id': '#breadcrumbs',
        itemListElement: breadcrumbs.map((item, index) => {
            const position = index + 1
            const bcItem = {
              '@type': "ListItem",
              '@id': `#breadcrumbs-${position}`,
              position: position,
              name: item.name
            }
            
            if (position < breadcrumbs.length) {
              bcItem.item = item.path
            } 
            
            return bcItem
        })
    }

    return structuredJson
}

/**
 * Builds a breadcrumbs' item {name: '', path: '', callback: function(){}}
 * @param  { String } name
 * @param  { String } path
 * @param  { Function } callback
 * @return { Object }
 */
module.exports.buildBreadcrumbsItem = (name, path, callback) => {
    return {
        name,
        path,
        callback
    }
}

/**
 * Finds an element in the path of event's element
 * @type {Element | null}
 */
module.exports.findAncestorByClassNameInEventPath = (e, className) => {
    let path = e.composedPath()
    let childElement = null
    if (path) {
        while (path.length && !childElement) {
            const element = path.shift()
            let regExp = ''
            if (className instanceof Array) {
                regExp = className.join('|')
            } else {
                regExp = className
            }
            if (element && typeof element.className === 'string' && element.className.match(new RegExp(regExp))) {
                childElement = element
            }
        }
    }

    return childElement
}

/**
 * Creates tree structure for creating table of contents from a given node
 * @param  {Array} nodes
 * @param  {Boolean} includeH4
 * @return {Array}
 */
module.exports.createTableOfContentsStructureFromContentful = (nodes, includeH3 = false, includeH4 = false) => {
    let headings = []
    nodes.forEach(({ content, nodeType }) => {
        if (nodeType.indexOf('heading-') === -1) {
            return false
        }
        const id = kebabCase(content[0].value)
        const label = content[0].value
        if (nodeType === 'heading-2') {
            headings.push({
                id,
                label,
                children: []
            })

            return false
        }

        const headingsLength = headings.length
        if (includeH3) {
            if (nodeType === 'heading-3' && headingsLength && headings[headingsLength - 1]) {
                headings[headingsLength - 1].children.push({
                    id,
                    label,
                    children: []
                })

                return false
            }
        }

        if (includeH4) {
            const subheadingsLength = headings[headingsLength - 1].children.length
            if (nodeType === 'heading-4' && subheadingsLength && headings[headingsLength - 1].children[subheadingsLength - 1]) {
                headings[headingsLength - 1].children[subheadingsLength - 1].children.push({
                    id,
                    label
                })

                return false
            }
        }
    })

    return headings
}



/**
 * Extracts attribute from list of potential attributes
 * @param  {ContentfulMenuItem} menuItem
 * @param  {string} attribute's name
 * @param  {string} defaultItemValue
 * @param  {boolean} appendDefault
 * @return {string}
 */
module.exports.extractValueFromAttributes = (menuItem, attribute, defaultItemValue, appendDefault) => {
    let attributeValue = defaultItemValue || ``
    if (menuItem.extraAttributes) {
        let extraAttributes = JSON.parse(get(menuItem, 'extraAttributes.internal.content', `{}`))
        if (attribute in extraAttributes) {
            attributeValue = appendDefault ? `${attributeValue} ${extraAttributes[attribute]}` : extraAttributes[attribute]
        }
    }

    return attributeValue
}

/**
 * Retrieves element's width and height 
 * @param  {String} selectorOrElement query selector such as `.className` or `#id` or the element itself
 * @return {Object}
 */
const getElementSizeBySelector = (selectorOrElement) => {
    const element = typeof selectorOrElement === 'string' ? document.querySelector(selectorOrElement) : selectorOrElement
    if (element) {
        let addedHeight = 0
        let addedWidth = 0
        if (typeof window !== 'undefined') {
            const style = window.getComputedStyle(element);
            addedHeight = parseInt(style.paddingTop) + parseInt(style.paddingBottom) + parseInt(style.marginTop) + parseInt(style.marginBottom)
            addedWidth = parseInt(style.paddingRight) + parseInt(style.paddingLeft) + parseInt(style.marginRight) + parseInt(style.marginLeft)
                // console.log([addedHeight, addedWidth])
        }
        return {
            height: element.offsetHeight + addedHeight,
            width: element.offsetWidth + addedWidth
        }
    }

    return null
}
module.exports.getElementSizeBySelector = getElementSizeBySelector

/**
 * Retrieves header's size
 * @return {Object}
 */
module.exports.getHeaderSize = () => {
    return getElementSizeBySelector('#app-header') || 0
}

/**
 * Decorates URL with google client ID
 * @param  {string} url
 * @return {string}
 */
const decorateWithGAClientId = (url) => {
    if (typeof window !== 'undefined') {
        if ('ga' in window && typeof ga.getAll === 'function') {
            // Handles GA cross-domain
            let trackers = ga.getAll()
            if (trackers.length) {
                linker = new window.gaplugins.Linker(trackers[0])
                url = linker.decorate(url)
            }
        }
    }

    return url
}
module.exports.decorateWithGAClientId = decorateWithGAClientId

/**
 * Retrieves Google Analytics Client ID
 * This is not the same as above. `decorateWithGAClientId` decorates the url with a different ID 
 * (seems google uses a longer one for cross domain tracking)
 * @return {string}
 */
const getGAClientId = () => {
    let id = null
    if (typeof window !== 'undefined' && 'ga' in window && typeof ga.getAll === 'function') {
        // Handles GA cross-domain
        let trackers = ga.getAll()
        if (trackers.length) {
            id = trackers[0].get('clientId')
        }
    }

    return id
}
module.exports.getGAClientId = getGAClientId

/**
 * Receives an url and decorates it with cookies as params
 * Reference: https://www.knewledge.com/en/blog/2013/11/cross-domain-tracking-for-links-with-gtm/
 * @param  { String } url
 * @return {[type]}
 */
module.exports.decorateWithCookies = (url) => {
    if (!url.match(/\?/)) {
        // Means url doesn't contain any parameter
        url = url + '?'
    }

    if (typeof window !== 'undefined') {
        url = decorateWithGAClientId(url)
        if ('convert' in window) {
            url = `${url}&_conv_v=${escape(convert.getCookie("_conv_v"))}&_conv_s=${escape(convert.getCookie("_conv_s"))}`
        }

        const cjEventCookie = Cookies.get('cjevent');
        if (cjEventCookie) {
            url = `${url}&CJEVENT=${escape(cjEventCookie)}`
        }

        //TODO - RevOffers
        //TODO - Talkable
    }

    return url;
}

/**
 * Creates a unique ID
 * Code extracted from: https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
 * @return {String}
 */
const createUUID = () => {
    var dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}


/**
 * Triggers an event for GTM
 * @param  {String} eventName
 * @param  {Object} eventData
 * @return {void}
 */
module.exports.triggerEvent = (eventName, eventData) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: `${eventName}`,
        event_id: createUUID(),
        ...eventData
    })
}

/**
 * Returns querystring as name:value Object
 * @param  { String } queryString
 * @return { Object }
 */
module.exports.queryParamsFromString = (queryString) => {
    const queryParams = {}
    if (queryString) {
        const queryParts = queryString.replace(/^\?/, '').split('&')
        queryParts.forEach(part => {
            const nameValue = part.split('=')
            if (nameValue) {
                queryParams[nameValue[0]] = nameValue.length > 1 ? decodeURIComponent(nameValue[1]) : null
            }
        })
    }

    return queryParams
}

/**
 * Returns querystring as a String
 * @param  { Object } queryString
 * @return { Object }
 */
module.exports.stringFromQueryParams = (queryParams) => {
    const queryString = []

    for (let i in queryParams) {
        queryString.push(`${i}=${encodeURIComponent(queryParams[i])}`)
    }

    return queryString.join('&')
}

/**
 * Applies smooth scroll
 * @param  {Event | String} eOrID
 * @param  {Number} offset - if set, will calculate height with it
 * @return {void}
 */
module.exports.handleInnerNavigation = (eOrID, offset = 0) => {
  let id
  if (typeof eOrID === 'string') {
    //Means it already is an id
    id = eOrID
  } else {
    const { target } = eOrID
    id = (target.getAttribute('href') || '').replace('#', '')
  }
  const targetElement = document.getElementById(id)
  if (targetElement) {
    typeof eOrID.preventDefault === 'function' && eOrID.preventDefault()
    const pos = targetElement.getBoundingClientRect()
    const targetTop = (pos.top + window.scrollY) + offset
    smoothscroll.polyfill();
    window.scroll({top: targetTop, left: 0, behavior: 'smooth' });
  }
}

/**
 * Creates an intersection observer
 * @param  {Array}   elements
 * @param  {Function} callback
 * @param  {Object}   observerProperties
 * @return {IntersectionObserver | null}
 */
module.exports.buildIntersectionObserver = (elements, callback, observerProperties = {}) => {
  let observer = {}
  if ('IntersectionObserver' in window) {
    observer = new IntersectionObserver((elements, observer) => {
      elements.forEach((element) => callback(element.target, element))
    }, observerProperties);
    
    elements.forEach(el => {
      observer.observe(el);
    });
  } else {
    elements.forEach((el) => callback(el))
  }
  
  return observer
}

/**
 * Checks if connection is a high speed one
 * @return {Boolean}
 */
module.exports.isHighSpeedConnection = () => {
  return isSafari() || isIphone() // Because for Safari and Iphone this won't work
      || (typeof navigator !== 'undefined' 
      && navigator.connection 
      && navigator.connection.downlink 
      && navigator.connection.downlink > 5);
}

/**
 * Moves scroll to position
 * @param  {number} top
 * @param  {number} left
 * @return {void}
 */
const scrollTo = (top, left) => {
  if (typeof window !== 'undefined' && 'scroll' in window) {      
    window.scroll({top, left, behavior: 'smooth' });
  } else {
    window.scrollTo(top, left)
  }
}
module.exports.scrollTo = scrollTo

/**
 * Attaches the "swipe" event listener to an element
 * @param  {DomElement} element
 * @param  {object} callbacks - expects an object containing callbacks for each type of "swiping": {up: () => {}, down: () => {}, left: () => {}, right: () => {}, tap: () => {}}
 * @param  {int} treshold - distance between touch start and touch end to be considered a swipe movement
 * @return {void}
 */
module.exports.attachSwipeListener = (element, callbacks, treshold = 10) => {
  let touchstartX = 0;
  let touchstartY = 0;
  let touchendX = 0;
  let touchendY = 0;

  const handleGesture = () => {
      if (touchendX < touchstartX - treshold) {
        if (typeof callbacks.left === 'function') {
          callbacks.left();
        }
      }
      
      if (touchendX > touchstartX + treshold) {
        if (typeof callbacks.right === 'function') {
          callbacks.right();
        }
      }
      
      if (touchendY < touchstartY - treshold) {
        if (typeof callbacks.up === 'function') {
          callbacks.up();
        }
      }
      
      if (touchendY > touchstartY + treshold) {
        if (typeof callbacks.down === 'function') {
          callbacks.down();
        }
      }
      
      if (touchendY === touchstartY) {
        if (typeof callbacks.tap === 'function') {
          callbacks.tap();
        }
      }
  }

  element.addEventListener('touchstart', function(event) {
      touchstartX = event.changedTouches[0].screenX;
      touchstartY = event.changedTouches[0].screenY;
  }, {passive: true});

  element.addEventListener('touchend', function(event) {
      touchendX = event.changedTouches[0].screenX;
      touchendY = event.changedTouches[0].screenY;
      handleGesture();
  }, {passive: true}); 
}

/**
 * Checks if is an android system
 * @return {Boolean}
 */
module.exports.isAndroid = () => {
  return typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase().indexOf("android") > -1
}

/**
 * Checks if is windows
 * @return {Boolean}
 */
module.exports.isWindows = () => {
  return typeof navigator !== 'undefined' && navigator.platform.indexOf('Win') > -1
  
}

/**
 * Checks if is Mac
 * @return {Boolean}
 */
module.exports.isMac = () => {
  return typeof navigator !== 'undefined' && navigator.platform.indexOf('Mac') > -1
}

/**
 * Checks if is Iphone
 * @return {Boolean}
 */
const isIphone = () => {
  return typeof navigator !== 'undefined' && navigator.platform.indexOf('iPhone') > -1
}
module.exports.isIphone = isIphone


/**
 * Checks if is Safari
 * @return {Boolean}
 */
const isSafari = () => {
  return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1
}
module.exports.isSafari = isSafari

module.exports.sendToKlaviyo = (list, email, fields, success = () => {}, fail = () => {}) => {
  const bodyFormData = new FormData();
  bodyFormData.set('g', list);
  bodyFormData.set('email', email);
  bodyFormData.set('$fields', Object.keys(fields).join(','));
  for (let i in fields) {
    if (fields[i]) {
      bodyFormData.set(i, fields[i])
    }
  }
  const options = {
    method: 'POST',
    data: bodyFormData,
    url: '//manage.kmail-lists.com/ajax/subscriptions/subscribe',
    headers: {'Content-Type': 'multipart/form-data' }
  };
  axios(options).then(success).catch(fail)

}

/**
 * Retrieves a classname from a color
 * @param  {String} color
 * @param  {String} [prefix='']
 * @param  {String} [defaultClass='']
 * @return {String}
 */
module.exports.colorToClass = (color, prefix = '', defaultClass = '') => {
  let colorClass = '';
  switch (color.toLowerCase()) {
    case 'blue': colorClass = 'primary'; break;
    case 'green': colorClass = 'success'; break;
    case 'dark gray': colorClass = 'dark-gray'; break;
    case 'light gray': colorClass = 'light-gray'; break;
    case 'white': colorClass = 'white'; break;
    case 'yellow': colorClass = 'secondary'; break;
  }
  
  return colorClass ? `${prefix}${colorClass}` : defaultCLass  
}

/**
 * Retrieves logged In User
 * @return {[type]}
 */
const loggedInUser = () => {
  const b64User = Cookies.get(SHOPIFY_CUSTOMER_COOKIE)
  let user = null
  if (b64User) {
    user = JSON.parse(atob(b64User))
  }
  return user
}
module.exports.loggedInUser = loggedInUser

/**
 * Retrieves US States
 * @type {Object}
 */
module.exports.usStates = {
    AL: "ALABAMA",
    AK: "ALASKA",
    AS: "AMERICAN SAMOA",
    AZ: "ARIZONA",
    AR: "ARKANSAS",
    CA: "CALIFORNIA",
    CO: "COLORADO",
    CT: "CONNECTICUT",
    DE: "DELAWARE",
    DC: "DISTRICT OF COLUMBIA",
    FM: "FEDERATED STATES OF MICRONESIA",
    FL: "FLORIDA",
    GU: "GUAM",
    GA: "GEORGIA",
    HI: "HAWAII",
    ID: "IDAHO",
    IL: "ILLINOIS",
    IN: "INDIANA",
    IA: "IOWA",
    KS: "KANSAS",
    KY: "KENTUCKY",
    LA: "LOUISIANA",
    ME: "MAINE",
    MH: "MARSHALL ISLANDS",
    MD: "MARYLAND",
    MA: "MASSACHUSETTS",
    MI: "MICHIGAN",
    MN: "MINNESOTA",
    MS: "MISSISSIPPI",
    MO: "MISSOURI",
    MT: "MONTANA",
    NC: "NORTH CAROLINA",
    ND: "NORTH DAKOTA",
    MP: "NORTHERN MARIANA ISLANDS",
    NE: "NEBRASKA",
    NH: "NEW HAMPSHIRE",
    NJ: "NEW JERSEY",
    NM: "NEW MEXICO",
    NY: "NEW YORK",
    NV: "NEVADA",
    OH: "OHIO",
    OK: "OKLAHOMA",
    OR: "OREGON",
    PW: "PALAU",
    PA: "PENNSYLVANIA",
    PR: "PUERTO RICO",
    RI: "RHODE ISLAND",
    SC: "SOUTH CAROLINA",
    SD: "SOUTH DAKOTA",
    TN: "TENNESSEE",
    TX: "TEXAS",
    UT: "UTAH",
    VT: "VERMONT",
    VI: "VIRGIN ISLANDS",
    VA: "VIRGINIA",
    WA: "WASHINGTON",
    WV: "WEST VIRGINIA",
    WI: "WISCONSIN",
    WY: "WYOMING",
}

const isTodayInRangeOfDates = (startDate, endDate) => {
  let currentDate = new Date
  let startDateTime = new Date(startDate)
  let endDateTime = new Date(endDate)
  if (currentDate.getTime() >= startDateTime.getTime() && currentDate.getTime() <= endDateTime.getTime())  {
    return true
  }
  return false
}
module.exports.isTodayInRangeOfDates = isTodayInRangeOfDates